<template>
  <div class="" style= "width:360px; margin: 30px auto 90px" >
    <h2 class="fw-bold mb-2 text-uppercase"> Painel de Admin</h2>
    <div
        class=""
        style="width: 540px; height:0; margin: 10px auto 10px"
    >
<!--      <i class="bi bi-info-circle"></i>-->
      <p class="text-black mb-5">
<!--        <svg-->
<!--            xmlns="http://www.w3.org/2000/svg"-->
<!--            width="16"-->
<!--            height="16"-->
<!--            fill="currentColor"-->
<!--            class="bi bi-info-circle"-->
<!--            viewBox="0 0 16 16"-->
<!--        >-->
<!--&lt;!&ndash;          <path&ndash;&gt;--> <!--&lt;!&ndash;              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;          <path&ndash;&gt;-->
<!--&lt;!&ndash;              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--        </svg>-->

      </p>
    </div>
  </div>
  <div v-if="!is_admin"  class="card text-black"
       style="
              border-radius: 1rem;
              box-shadow: 1px 1px 2px 1px rgba(20, 20, 50, 0.5);
            width: 540px ; max-width: 1200px; margin: 100px auto 150px" >

    <input v-model="user.username">
    <input v-model="user.password" type="password">
    <button @click="authAdmin()">Login</button>
  </div>
  <div v-else>
    <input v-model="id" type="number">
    <button @click="getDataAll()">Todos</button>
    <button @click="getDataTrue()">Aprovados</button>
    <button @click="getData()">Não Aprovados</button>
    <h1 style="text-align: center; fontsize: 20px">Lista de Docentes</h1> <button @click="exportTable()">Gerar Excel</button>

    <table class="table table-bordered">
      <thead class="thead">
      <tr>

        <th>ID Unidade Académica</th>
        <th>Unidade Académica</th>
        <th>Departamento</th>
        <th>Departamento ID</th>
        <th>Outro Departamento</th>
        <th>Nome completo</th>
        <th>Email Institucional</th>
        <th>Email pessoal</th>
        <th>Telefone</th>
        <th>Função</th>
        <th>Estado</th>
        <th>Acção</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="item in datasToShow" :key="item.id" type="checkbox">
        <td>{{ item.unidadeAcademic.id }}</td>
        <td>{{ item.unidadeAcademic ? item.unidadeAcademic.name : "" }}</td>
        <td>{{ item.departamento.name }}</td>
        <td><input type="number" v-model="item.department" @change="updateDepartment(item)"></td>
        <td>{{ item.outro }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.email }}</td>
        <td>{{ item.email1 }}</td>

        <td>{{ item.telefone }}</td>
        <td>{{item.funcao}}</td>

        <td>
          <!-- <input type="checkbox" id="checkbox" v-model="checked">
        <label for="checkbox">{{ checked }}</label> -->
          {{ item.verified ? "Aprovado" : "Nao aprovado" }}
        </td>
        <!-- <label class="switch">
            <input type="checkbox" >
            <span class="slider round"></span>
          </label>
               <label class="switch">
            <input type="checkbox" verified>
            <span class="slider round"></span>
          </label> -->
      </tr>
      </tbody>
    </table>
    <div class="btn-toolbar" style="margin-top: 15px; width: 80vw">
      <div>
        <button
            @click="startPage()"
            class="btn btn-outline-success px-2; margin-rigth:10px; text-black"
        >
          Inicio
        </button>
      </div>
      <div>
        <button
            @click="previousPage()"
            :disabled="startItem <= 0"
            class="btn btn-success px-2 text-black"
        >
          Anterior
        </button>
      </div>
      <div>
        <button
            @click="nextPage()"
            :disabled="endItem >= datas.length"
            class="btn btn-outline-success px-2 text-black"
        >
          Próximo
        </button>
      </div>
      <div>
        <button
            @click="endPage()"
            class="btn btn-outline-success px-2; text-black"
        >
          Fim
        </button>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import * as XLSX from "xlsx";
import { ref } from "vue";
import { initializeApp } from "firebase/app";
import { mapGetters, mapActions } from "vuex";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  onSnapshot,
  query,
  limit,
  where,
  updateDoc
} from "firebase/firestore";
import axios from "axios";
import { getDatabase } from "firebase/database";
import CoreDialog from "../components/dialogs/CoreDialog.vue";
export default {
  name: "AdminView",
  components: {
    CoreDialog,
  },
  computed: {
    ...mapGetters("authenticated", ["authenticatedGetter"]),
  },
  setup() {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
     const firebaseConfig = {
    apiKey: "AIzaSyDfJ1jAl_WcMPtm2-I-cxHcRt2lLGWsHK4",
    authDomain: "formulario-dabc5.firebaseapp.com",
    projectId: "formulario-dabc5",
    storageBucket: "formulario-dabc5.appspot.com",
    messagingSenderId: "789638100462",
    appId: "1:789638100462:web:265b0c88c5f487ff024aff",
    measurementId: "G-LJH7EE9PGS"
  };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore();
    const database = getDatabase();

    return {
      app,
      db,
    };
  },
  data() {
    return {
      datas: [],
      datasToShow: [],
      startItem: 0,
      endItem: 20,
      isActive: false,
      detailsDialog: false,
      successDialog: false,
      detailsItem: null,
      is_admin: false,
      user: {
        username: "",
        password: "",
      },
      id: 0
    };
  },
  methods: {
    updateDepartment(item) {
      console.log(item.department)
      console.log(item.id)

      if (item.department) {
        const docReference = doc(this.db, "docentes", item.id);

        // let data = item
        // data.verified = item.verified ? false : true
        setDoc(
            docReference,
            { department: item.department },
            { merge: true }
        )

      }

    },
    getDepartaments(organicUnit) {
      // return console.log(organicUnit)
      return axios
          .get("https://api.siga.uem.mz/organicunit/department/", {
            headers: {
              Authorization:"Token 09368f61b2402bd7976bb92a02759167e45c4a5b",
            },
            params: {
              organic_unit: organicUnit
            }
          })
    },
    nextPage() {
      this.startItem = this.endItem;
      this.endItem += 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },
    previousPage() {
      this.endItem = this.startItem;
      this.startItem -= 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },
    startPage() {
      this.startItem = 0;
      this.endItem = 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },

    endPage() {
      this.startItem = this.datas.length - 20;
      this.endItem = this.datas.length;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },



    async getData() {
      // console.log(this.data);
      // console.log(this.departmentGetter)
      const q = query(collection(this.db, "docentes"), where("verified", "==", false));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let datas = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          datas.push(data);
        });
        datas.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
        this.datas = datas;
        this.datasToShow = this.datas.slice(this.startItem, this.endItem);
        // console.log(this.datas);
      });
      //   await addDoc(collection(this.db, "docentes"), this.data);
      // Enviar os dados para o firebase
    },


    async getDataTrue() {
      // console.log(this.data);
      // console.log(this.departmentGetter)
      const q = query(collection(this.db, "docentes"), where("verified", "==", true));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let datas = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          datas.push(data);
        });
        datas.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
        this.datas = datas;
        this.datasToShow = this.datas.slice(this.startItem, this.endItem);
        // console.log(this.datas);
      });
      //   await addDoc(collection(this.db, "docentes"), this.data);
      // Enviar os dados para o firebase
    },

    async getDataAll() {
      // console.log(this.data);
      // console.log(this.departmentGetter)
      const q = query(collection(this.db, "docentes"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let datas = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          datas.push(data);
        });
        datas.sort(function (a, b) {
          return b.timestamp - a.timestamp;
        });
        this.datas = datas;
        this.datasToShow = this.datas.slice(this.startItem, this.endItem);
        // console.log(this.datas);
      });
      //   await addDoc(collection(this.db, "docentes"), this.data);
      // Enviar os dados para o firebase
    },



    exportTable() {
      let jsonData = this.datas;

      // return console.log(jsonData)
      if (this.id) {
        // Muito temporario






          jsonData = jsonData.filter(data => {
            if(data.unidadeAcademic.id == this.id) {
              return data
          }
        })

        // this.getDepartaments(this.id).then(response => {
        //   console.log(response.data)
        //   // for (let i = 0; i < response.data.length; i++) {
        //   //   console.log(response.data[i].name)
        //   // }
        //   for (let i = 0; i < jsonData.length; i++) {
        //     console.log(jsonData[i].outro)
        //     for (let j = 0; j < response.data.length; j++) {
        //       console.log("======================")
        //       console.log(response.data[j].name)
        //       console.log(jsonData[i].outro)
        //       console.log("======================")
        //       if(jsonData[i].outro && response.data[j].name && jsonData[i].outro.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", "") === response.data[j].name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(" ", "").replace(" ", "").replace(" ", "").replace(" ", "")) {
        //         // alert(111112222)
        //         console.log(jsonData[i].id)
        //         const docReference = doc(this.db, "docentes", jsonData[i].id);
        //         // getDoc(docRef)
        //         // let data = item
        //         // data.verified = item.verified ? false : true
        //         console.log(response.data[j].id)
        //         setDoc(
        //             docReference,
        //             { department: response.data[j].id },
        //             { merge: true }
        //         ).then(ressss => {
        //
        //         })
        //       }
        //     }
        //   }
        // }).catch(error => {
        //   console.log(error)
        // })

        // jsonData[0]


      }

      const wb = XLSX.utils.book_new();

      const rows = jsonData.map(row => ({
        ' ID unidade Academica': row.unidadeAcademic.id,
        ' unidade Academica': row.unidadeAcademic.name,
        'Nome': row.name,
        'Email Pessoal': row.email1,
        'Email Institucional': row.email,
        'Número do documento': row.n_doc,
        'Tipo de documento': row.documento,
        'Nuit': row.nuit,
        'Telefone': row.telefone,
        'Bairro do Utilizador': row.bairroutilizador,
        'Quarteirao': row.quarteirao,
        'Rua': row.ruautilizador,
        'Cidade': row.cidadeutilizador,
        'Numero de Casa': row.ncasa,
        'Departamento': row.departamento.name,
        'Departamento ID': row.department,
         'Outro Departamento':row.outro,
        'Função':row.funcao,
      }));

      console.log(rows)
      const worksheet = XLSX.utils.json_to_sheet(rows);
      console.log(worksheet)

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Docentes");
      XLSX.writeFile(workbook, "ListaDocentes.xlsx");
    },


    // Auth admin
    authAdmin() {
      if (this.user.username !== "" && this.user.password !== "") {
        axios
            .post("https://api.siga.uem.mz/account/token/", this.user)
            .then((response) => {
              console.log(response.data);
              axios
                  .get(
                      `https://api.siga.uem.mz/account/user/${this.user.username}/`,
                      {
                        headers: {
                          Authorization: `Token ${response.data.token}`,
                        },
                      }
                  )
                  .then((responseAdmin) => {
                    console.log(responseAdmin.data);
                    if (responseAdmin.data.admin) {
                      this.is_admin = true
                      this.getData();
                    } else {
                      alert("Não é admin")
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                    alert("Não tem permissão para aceder a esta pagina.");
                  });
            })
            .catch((error) => {
              console.log(error);
            });
      } else {
        console.log("Um nome de usuário e senha devem estar presentes");
      }
    }
  },
  mounted() {
    // this.getAcademicsUnities()

    if (this.is_admin) {
      this.getData();
    }
  },
};

function writeUserData(name, email) {
  const db = getDatabase();
  set(ref(db, "users/" + userId), {
    username: name,
    email: email,
    profile_picture: imageUrl,
  });

}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(228, 54, 54);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #41ad26;
}

input:focus + .slider {
  box-shadow: 0 0 1px 41ad26;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
