 <template>
  
 <router-view>
    
 </router-view>
 
</template>


 <style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

//  table, th, td {
//   border: 1px solid black;
//   border-collapse: collapse;
// }


// .forms, h1{
//   position:center;
//   left:50%;
//   top:50%;
//   width:1000px;
//   height:30px;
//   display: inline-block;
//   box-shadow: 2px 3px 6px rgba(0,0,0,.09); 
// }

// .tipo_entidade {
// //  //left:40px;
// //  //right: 40px;
// width: 470px;
// //  //height: 40px;
// //  padding: 0.3px 15px;
// //  display: inline-flex;
// //  // margin-top: 10px;

// }

// .col{
//   width: 370px;
//   display: inline-flex;
//   flex: 1 0 0%;
// }

// .enviar{
//   float: right;
//   margin-right: 80px;
//   padding: 20px;
// }

// .btn {
//   display: inline-block;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #212529;
//   text-align: center;
//   text-decoration: none;
//   vertical-align: middle;
//   cursor: pointer;
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   user-select: none;
//   background-color: transparent;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   border-radius: 0.25rem;
//   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

// .btn-primary {
//   color: #fff;
//   background-color: #0d6efd;
//   border-color: #0d6efd;
// }
// .btn-primary:hover {
//   color: #fff;
//   background-color: #0b5ed7;
//   border-color: #0a58ca;
// }
// .row{
//   display: inline-flex;
//   padding: 10px;
//   width: 100%;
// }

// .email {
// // left: 40px;
// // right:40px;
// margin-top:10px;
// width:400px;
// display: inline-flex;
// }

// .email1{
//   //left:40px;
//   width: 400px;
//   //margin-top: 10px;
//   display: inline-flex;
// }

// .row-email{
// display: inline-flex;
// padding: 10px;
// padding: 0.3px 15px;
// margin: 0px;
//  width: 430px;
// }
// .row-nuit{
//   left: 40px;
//   width: 340px;
//   margin-top: 10px;
//   display: inline-flex;
//   padding: 1.3px 15px;
//   //margin-block: 10px;
// }

//  .tipo_documento{
// //   left: 40px;
//   width: 260px;
// //   margin-top: 10px;
//  }

//  .ndocumento{
// //   left: 40px;
//   width: 480px;
// //   margin-top: 10px;
// display: inline-flex;
// }

// .row-documento{
//   display: inline-flex;
//   padding: 10px;
// padding: 0.3px 15px;
// margin: 0px;
// }
// .bairro_utilizador{
//   //left: 40px;
//   // right: 50px;
//    width: 380px;
//   // height: 200px;
//   margin-top: 10px;
// }
// .row-endereco{
//    display: inline-flex;
// padding: 10px;
// padding: 1.3px 15px;
// margin: 0px;
//  width: 400px;
// }

// .row-rua{
//      display: inline-flex;
// padding: 10px;
// padding: 1.3px 15px;
// margin: 0px;
//  width: 400px;
// }

// .row-casa{
//        display: inline-flex;
// padding: 10px;
// padding: 1.3px 15px;
// margin: 0px;
//  width: 400px;
// }
// fieldset
// {
//   width:1000px;
//   height:500px;
// }

// legend
// {
//   font-style:bold;
//   font-family: “Segoe UI”,”Arial”,”Times New Roman”;
// }

//   #icon {
//       display: inline-block;
//       padding: 9.3px 15px;
//       //box-shadow: 1px 2px 5px rgba(0,0,0,.09); 
//       background: #6d777e;
//       color: #fff;
//       text-align: center;
//       }

    


</style>


<style lang="sass">
.alert alert-info
  @media (max-width: 390px)
    font-size: 0.8rem !important
.card
  @media (max-width: 844px)
    font-size: 1rem !important
</style>