<template>
  <div
    class="x-dialog"
    :style="`${'height: ' + heightDialog + 'px'}`"
    v-if="dialog"
  >
    <div class="container">
      <div
        class="row align-items-center justify-content-md-center"
        style="min-height: 70vh"
      >
        <div class="col-md-6">
          <div class="card" style="width: 100%">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title" v-if="title">{{ title }}</h5>
                </div>
                <div class="col"></div>
                <div class="col text-end">
                  <button
                    type="button"
                    class="close btn btn-sm btn-outline-secondary"
                    aria-label="Close"
                    @click="$emit('close')"
                  >
                    Fechar
                  </button>
                </div>
              </div>
              <div>
                <slot name="content"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    heightDialog: {
      type: Number,
      default: 1000,
    },
  },
  setup() {
    return {
      heightDialog: ref(1000),
    };
  },
  mounted() {
      if(document.body.clientHeight > 1000) {
        this.heightDialog = document.body.clientHeight;
      }
  },
};
</script>

<style>
.x-dialog {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.x-card {
  display: flex;
  align-items: center;
  align-self: center;
}
</style>
