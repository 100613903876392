<template>
  <section class="vh-100 gradient-custom">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div
            class="card text-black"
            style="
              border-radius: 1rem;
              box-shadow: 1px 1px 2px 1px rgba(20, 20, 50, 0.5);
            "
          >
            <div class="card-body p-5 text-center">
              <div class="text-center">
                <img
                  src="../assets/Logo-UEM-transparente1.png"
                  style="width: ; max-width: 100px; margin: 5px auto 5px"
                  alt="UEM"
                  class="img-thumbnail"
                />
              </div>
              <div class="mb-md-5 mt-md-4 pb-5">
                <h2 class="fw-bold mb-2 text-uppercase">Login</h2>
                <div
                  class="alert alert-info"
                  style="width: 100%; height: 130px; margin: 20px auto 20px"
                >
                  <i class="bi bi-info-circle"></i>
                  <p class="text-black mb-5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                      />
                    </svg>
                    Através desta aplicação os Chefes de Departamento deverão
                    validar os dados referentes aos seus docentes, para fins de
                    actualização no SIGA 2.0
                  </p>
                </div>

                <form @submit.prevent.stop="login()">
                  <div class="form-outline form-white mb-4">
                    <input
                      type="email"
                      id="typeEmailX"
                      class="form-control form-control-lg"
                      style="font-size: 1rem"
                      v-model="input.username"
                      placeholder="Email institucional @uem.mz"
                    />
                    <label class="form-label" for="typeEmailX"
                      >Email institucional</label
                    >
                  </div>

                  <div class="form-outline form-white mb-4">
                    <input
                      type="password"
                      id="typePasswordX"
                      class="form-control form-control-lg"
                      style="font-size: 1rem"
                      v-model="input.password"
                      placeholder="Use a senha do SIGA"
                    />
                    <label class="form-label" for="typePasswordX">Senha</label>
                  </div>

                  <button class="btn btn-success px-5" type="submit">
                    Entrar
                  </button>
                  <span><router-link to="/lista"></router-link></span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {departmentAction} from "@/store/auth/actions";
export default {
  name: "Login",
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("authenticated", ["authenticatedGetter", "departmentGetter"]),
  },

  methods: {
    ...mapActions("authenticated", ["authenticatedAction", "departmentAction"]),
    login() {
      if (this.input.username != "" && this.input.password != "") {
        axios
          .post("https://api.siga.uem.mz/account/token/", this.input)
          .then((response) => {
            console.log(response.data);
            axios
              .get(
                `https://api.siga.uem.mz/organicunit/deparment/deparment-heade-master/by-username/${this.input.username}/`,
                {
                  headers: {
                    Authorization: `Token ${response.data.token}`,
                  },
                }
              )
              .then((responseDirector) => {
                console.log(responseDirector.data);
                this.authenticatedAction(true);
                this.departmentAction(responseDirector.data)
                // this.$router.replace("/lista");
                this.$router.push("/lista");
                // alert("login efectuado com sucesso.");
              })
              .catch((error) => {
                console.log(error);
                alert("Não tem permissão para aceder a esta pagina.");
              });
          })
          .catch((error) => {
            console.log(error);
            alert("Algum erro de autenticação. Insira correctamente os dados de autenticação.");
          });
      } else {
        console.log("Um nome de usuário e senha devem estar presentes");
      }
      // this.$router.replace("/list");
    },
  },
};
</script>

<style scoped>
#login {
  width: 500px;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  margin: auto;
  margin-top: 200px;
  padding: 20px;
}
</style>