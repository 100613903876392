export function authenticatedAction (context, val) {
    context.commit("authenticatedMutation", val)
    
}

export function departmentAction (context, val) {
    context.commit("departmentMutation", val)

}

