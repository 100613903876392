
<template>
  <div class="text-center">
    <img
      src="../assets/Logo-UEM-transparente1.png"
      style="
        width: 100%;
        hight: auto;
        max-width: 150px;
        margin: 5px auto 5px;
        margin-bottom: 30px;
      "
      alt="UEM"
      class="img-thumbnail"
    />
  </div>

  <h1 style="text-align: center">Formulário de Registo de Docentes</h1>

  <div
    class="alert alert-info"
    style="width: 1080px; height: 100px; margin: 20px auto 20px"
  >
    <i class="bi bi-info-circle"></i>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-info-circle"
      viewBox="0 0 16 16"
    >
      <path
        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      />
      <path
        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
      />
    </svg>
    O presente formulário destina-se à actualização de dados pertencentes aos
    docentes de todas as Unidades Académicas da UEM, com vista à criação dos
    seus perfís no SIGA2.0, bem como a criação do email institucional @uem.mz, para os que não o possuam.
    
   <p style="padding-top: 10px">  <strong> Atenção: Caso não  possua o email institucional @uem.mz, deixe em branco o campo correspondente.</strong></p>
  </div>

  <div
    class="card"
    style="
      width: 100%;
      hight: 100%;
      width: 1080px;
      height: 540px;
      margin: 20px auto 20px;
    "
  >
    <div class="card-body">
      <form name="meu_form" @submit.prevent.stop="sendData()">
        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="entidade"
                >Unidade Académica</span
              >
              <select
                name="documento"
                class="form-control"
                v-model="data.unidadeAcademic"
                required="required"
                @change="getDepartaments(data.unidadeAcademic)"
              >
                <option
                  v-for="item in academicOrganics"
                  :key="item.id"
                  :value="{ id: item.id, name: item.name }"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="name">Nome completo</span>
              <input
                class="form-control"
                v-model="data.name"
                type="text"
                id="nameid"
                placeholder=""
                required="required"
              />
            </div>
          </div>
          
        </div>

        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="email" value="@uem.mz"
                >Email Institucional</span
              >
              <!-- <span v-if="msg.email">{{msg.email}}</span> -->
              <input
                @change="verificarDominio"
                class="form-control"
                v-model="data.email"
                type="email"
                id="emailid"
                placeholder="@uem.mz - deixe em branco caso não o possua"
                />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="email1">Email Pessoal</span>
              <input
                class="form-control"
                v-model="data.email1"
                type="text"
                id="email1id"
                placeholder=""
                n
              />
            </div>
          </div>
        </div>

        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="tipodocumento"
                >Tipo de documento
              </span>
              <select
                name="documento"
                class="form-control"
                v-model="data.documento"
              >
                
                <option
                  v-for="(value, index) in data.options"
                  :value="value"
                  :key="index"
                >
                  {{ value }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="n_doc">Nº do documento</span>
              <input
                class="form-control"
                v-model="data.n_doc"
                type="txt"
                id="N_Docid"
                placeholder=""
                required="required"
                maxlength="13"
              />
            </div>
          </div>
        </div>

        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="NUIT">NUIT</span>
              <input
                class="form-control"
                v-model="data.nuit"
                pattern="[0-9]{9}" title="O Nuit deve conter apenas números"
                id="nuitid"
                placeholder=""
                minlength="9"
                maxlength="9"
                n
              />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="telefone">Telefone</span>
              <input
                class="form-control"
                v-model="data.telefone"
                pattern="[0-9]{9}" title="O telefone deve conter apenas números"
                id="telefoneid"
                placeholder=""
                required="required"
                minlength="9"
                maxlength="9"
              />
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="Bairro"
                >Bairro de residência</span
              >
              <input
                class="form-control"
                v-model="data.bairroutilizador"
                type="text"
                id="bairroid"
                placeholder=""
                required="required"
              />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="quarteirao">
                Nº de quarteirão</span
              >
              <input
                class="form-control"
                v-model="data.quarteirao"
                type="number"
                id="quarteiraoid"
              />
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="Ruautilizador">
                Rua/Av. de residência</span
              >
              <input
                class="form-control"
                v-model="data.ruautilizador"
                type="text"
                id="cidadeid"
                placeholder=""
                required="required"
              />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="cidadeutilizador">
                Cidade de residência</span
              >
              <input
                class="form-control"
                v-model="data.cidadeutilizador"
                type="text"
                id="cidadeid"
              />
            </div>
          </div>
        </div>

        <div class="row g-3">
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="ncasa"> Nº de casa</span>
              <input
                class="form-control"
                v-model="data.ncasa"
                type="number"
                id="ncasaid"
              />
            </div>
          </div>

          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="funcao">Função</span>
              <select
                  name="funcao"
                  class="form-control"
                  v-model="data.funcao"
                  required="required"
              >
<!--                <option value="Director Adjunto para a Pós-Graduação">Director-Adjunto para a Pós-Graduação</option>-->
<!--                <option value="Director Adjunto para a Graduação ">Director-Adjunto para a Graduação </option>-->
<!--                <option value="Director de Curso " >Director de Curso </option>-->
<!--                <option value="Chefe de Departamento Académico " >Chefe de Departamento Académico</option>-->
<!--                <option value="Chefe de Registo Académico " >Chefe de Registo Académico</option>-->
                <option value="Docente" >Docente</option>


            </select>
            </div>
          </div>
        
          <div class="col-md-6 col-12">
            <div class="input-group mb-3">
              <span class="input-group-text" id="tipodepartamento"
                >Departamento
              </span>
              <select
                name="Departamento"
                class="form-control"
                v-model="data.departamento"

              >
                
                <option
                    v-for="item in data.options1"
                    :key="item.id"
                    :value="{ id: item.id, name: item.name }"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
<!--          <div class="col-md-6 col-12">-->
<!--            <div class="input-group mb-3">-->
<!--              <span class="input-group-text" id="outro">Outro Departamento</span>-->
<!--              <input-->
<!--                class="form-control"-->
<!--                v-model="data.outro"-->
<!--                type="text"-->
<!--                id="outroid"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
          </div>
        <div style="margin-top: 30px; center: 80px; margin-buttom: 50px">
          <button
              :disabled="loadForm"
              class="btn btn-success px-5; d-grid gap-2 col-6 mx-auto"

              type="submit"
          >
            <div class="row">
              <span class="col">Submeter Formulário </span>
              <span class="col" v-if="loadForm">
              <div class="spinner-border" role="status"></div>
              </span>
            </div>

          </button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { ref } from "vue";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
} from "firebase/firestore";

import axios from "axios";
export default {
  name: "HomeView",
  components: {
    HelloWorld,
  },
  data() {
    return {
      valido: false,
      loadForm: false,
      data: {
        //  Nº: ref(""),
        unidadeAcademic: ref(null),

        name: ref(""),
        email: ref(""),
        email1: ref(""),
        n_doc: ref(""),
        documento: ref(""),
        nuit: ref(""),
        telefone: ref(""),
        BairroUtilizador: ref(""),
        quarteirao: ref(""),
        Ruautilizador: ref(""),
        Cidadeutilizador: ref(""),
        ncasa: ref(""),
        funcao: ref(""),
        departamento: ref(""),
        outro: ref(""),
        verified: false,
        options: ["BI", "Passaport"],
        options1: [],
      },

      academicOrganics: [],
    };
  },

  setup() {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyDfJ1jAl_WcMPtm2-I-cxHcRt2lLGWsHK4",
      authDomain: "formulario-dabc5.firebaseapp.com",
      projectId: "formulario-dabc5",
      storageBucket: "formulario-dabc5.appspot.com",
      messagingSenderId: "789638100462",
      appId: "1:789638100462:web:265b0c88c5f487ff024aff",
      measurementId: "G-LJH7EE9PGS"
    };


    const app = initializeApp(firebaseConfig);
    const db = getFirestore();
   

    return {
      app,
      db,
      confirmRecaptcha: ref(false),
    };
  },
  methods: {
    verificarDominio() {
      let splitedString;
      let emailInstitucional = this.data.email;
      splitedString = emailInstitucional.split("@");
      
      if (emailInstitucional && splitedString[1] != "uem.mz") {
        //alerta emitido caso o dominio inserido seja diferente de uem.mz
        window.alert(
          "O dominio inserido é invalido. Por favor introduza o email com terminação @uem.mz"
        );
        this.valido = false;
      } else {
        this.valido = true;
      }
    },

    getDepartaments(organicUnit) {
      // return console.log(organicUnit)
      axios
          .get("https://api.siga.uem.mz/organicunit/department/", {
            headers: {
              Authorization:"Token 09368f61b2402bd7976bb92a02759167e45c4a5b",
             },
            params: {
              organic_unit: organicUnit.id
            }
          })
          .then((response) => {
            // console.log(response.data);
            this.data.options1 = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    async sendData() {
      this.loadForm = true
      if (!this.valido) {
        this.verificarDominio();
        // this.loadForm = false
      }
      if (this.valido) {
        // console.log(this.data);
        let data = this.data
        data.timestamp =Date.now('July 20, 2021 20:17:40')
        data.department = data.departamento ? data.departamento.id : ""
        await addDoc(collection(this.db, "docentes"), data).then((res) => {
          alert(
            "Os dados a serem enviados serão validados pelo Chefe de Departamento."
          );
          this.data = {
            unidadeAcademic: null,
            name: "",
            email: "",
            email1: "",
            n_doc: "",
            documento: "",
            nuit: "",
            telefone: "",
            BairroUtilizador: "",
            quarteirao: "",
            Ruautilizador: "",
            Cidadeutilizador: "",
            ncasa: "",
            funcao:"",
            departamento: "",
            Outro:"",
            verified: false,
            options: ["BI", "Passaport"],
            options1: [],
          }
          alert("Os dados foram enviados com sucesso.");
          this.loadForm = false
        });

      } else {
        alert(
          "Erro ao enviar os seus dados, certifique-se de ter enviado correctamente os seus dados"
        );
        this.loadForm = false
      }
    },

    getAcademicsUnities() {
      axios
          .get("https://api.siga.uem.mz/organicunit/colleges/", {
          headers: {
            Authorization: "Token 09368f61b2402bd7976bb92a02759167e45c4a5b",
            
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.academicOrganics = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    verifyRecaptchaCallback: function (response) {
      // console.log(response);
      this.confirmRecaptcha = true;
    },
  },
  mounted() {
    this.getAcademicsUnities();
    window.verifyRecaptchaCallback = this.verifyRecaptchaCallback;
  },
};
</script>

<style lang="sass">
.alert

  @media (max-width: 767px )
    width: 100% !important
    height: 100% !important
.card
  @media (max-width: 767px)
    width: 100% !important
    border: none !important
</style>