import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


createApp(App).use(store).use(router).mount('#app')

// new vue(
//     {
//         router,
//         render: h => h(listadocente),

//     }

// )
//     .$mount('#listadocente');



