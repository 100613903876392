<template>
  <h1 style="text-align: center; fontsize: 20px">Lista de Docentes</h1>

  <table class="table table-bordered">
    <thead class="thead">
    <tr>
      <th>Unidade Académica</th>
      <th>Nome completo</th>
      <th>Email Institucional</th>
      <th>Email pessoal</th>
      <th>Telefone</th>
      <th>Função</th>
      <th>Estado</th>
      <th>Acção</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="item in datasToShow" :key="item.id" type="checkbox">
      <td>{{ item.unidadeAcademic ? item.unidadeAcademic.name : "" }}</td>
      <td>{{ item.name }}</td>
      <td>{{ item.email }}</td>
      <td>{{ item.email1 }}</td>
      <td>{{ item.telefone }}</td>
      <td>{{item.funcao}}</td>

      <td>
        <!-- <input type="checkbox" id="checkbox" v-model="checked">
      <label for="checkbox">{{ checked }}</label> -->
        {{ item.verified ? "Aprovado" : "Nao aprovado" }}
      </td>

      <td>
        <!-- <button id ="ButtonDemo" @click="switchApprove(item)">Aprovar</button> -->
        <div class="row">
          <div class="col mr-2 mb-2">
            <button
                type="button"
                class="btn btn-success"
                @click="openDetails(item)"
            >
              Detalhes
            </button>
          </div>
        </div>
      </td>
      <!-- <label class="switch">
          <input type="checkbox" >
          <span class="slider round"></span>
        </label>
             <label class="switch">
          <input type="checkbox" verified>
          <span class="slider round"></span>
        </label> -->
    </tr>
    </tbody>
  </table>
  <div class="btn-toolbar" style="margin-top: 15px; width: 80vw">
    <div>
      <button
          @click="startPage()"
          class="btn btn-outline-success px-2; margin-rigth:10px; text-black"
      >
        Inicio
      </button>
    </div>
    <div>
      <button
          @click="previousPage()"
          :disabled="startItem <= 0"
          class="btn btn-success px-2 text-black"
      >
        Anterior
      </button>
    </div>
    <div>
      <button
          @click="nextPage()"
          :disabled="endItem >= datas.length"
          class="btn btn-outline-success px-2 text-black"
      >
        Próximo
      </button>
    </div>
    <div>
      <button
          @click="endPage()"
          class="btn btn-outline-success px-2; text-black"
      >
        Fim
      </button>
    </div>
  </div>
  <CoreDialog
      v-if="detailsDialog && detailsItem"
      :dialog="detailsDialog"
      @close="detailsDialog = false"
      :title="'Detalhes do Docente'"
  >
    <template v-slot:content>
      <div v-if="detailsItem">
        <div style="font-size: large">
          <li>
            <label>Unidade Academica</label>:
            {{
              detailsItem.unidadeAcademic
                  ? detailsItem.unidadeAcademic.name
                  : ""
            }}
          </li>
          <li><label>Nome</label>: {{ detailsItem.name }}</li>
          <li><label>Departamento</label>: {{ detailsItem.departamento }}</li>
          <li><label>Outro Departamento</label>: {{ detailsItem.outro }}</li>
          <li><label>Email</label>: {{ detailsItem.email }}</li>
          <li><label>Email</label>: {{ detailsItem.email1 }}</li>
          <li><label>Documento</label>: {{ detailsItem.documento }}</li>
          <li><label>Numero do documento</label>: {{ detailsItem.n_doc }}</li>
          <li><label>Nuit</label>: {{ detailsItem.nuit }}</li>
          <li><label>Telefone</label>: {{ detailsItem.telefone }}</li>
          <li><label>Bairro</label>: {{ detailsItem.bairroutilizador }}</li>
          <li><label>Quarteirão</label>: {{ detailsItem.quarteirao }}</li>
          <li><label>Rua</label>: {{ detailsItem.ruautilizador }}</li>
          <li><label>Cidade</label>: {{ detailsItem.cidadeutilizador }}</li>
          <li><label>Número de Casa</label>: {{ detailsItem.ncasa }}</li>
          <li><label>Função</label>: {{ detailsItem.funcao }}</li>


        </div>
        <div class="row">
          <div
              class="pr-4 col-2"
              role="toolbar"
              aria-label="Toolbar with button groups"
          >
            <div class="btn-group mr-2" role="group" aria-label="First group">
              <button
                  type="button"
                  class="btn btn-success"
                  @click="switchButton(detailsItem, true)"
              >
                Aprovar
              </button>
              <!-- <button
                type="button"
                class="btn btn-success"
                @click="openDetails(detailsItem)"
              >
                Detalhes
              </button> -->
            </div>
          </div>
          <div
              class="pr-4 col-3"
              role="group"
              aria-label="Toolbar with button groups"
          >
            <div class="btn-group mr-1" role="group" aria-label="First group">
              <button
                  type="button"
                  class="btn btn-danger"
                  @click="switchButton(detailsItem, false)"
                  style="width: 100%"
              >
                Não Aprovar
              </button>
              <!-- <button type="button" class="btn btn-danger"  @click="switchButton(item)">Não Aprovar</button> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </CoreDialog>
  <div v-if="detailsItem">
    <div style="font-size: large">
      <li>
        <label>Unidade Academica</label>:
        {{
          detailsItem.unidadeAcademic
              ? detailsItem.unidadeAcademic.name
              : ""
        }}
      </li>
      <li><label>Nome</label>: {{ detailsItem.name }}</li>
      <li><label>Email</label>: {{ detailsItem.email }}</li>
      <li><label>Email</label>: {{ detailsItem.email1 }}</li>
      <li><label>Documento</label>: {{ detailsItem.documento }}</li>
      <li><label>Numero do documento</label>: {{ detailsItem.n_doc }}</li>
      <li><label>Nuit</label>: {{ detailsItem.nuit }}</li>
      <li><label>Telefone</label>: {{ detailsItem.telefone }}</li>
      <li><label>Bairro</label>: {{ detailsItem.bairroutilizador }}</li>
      <li><label>Quarteirão</label>: {{ detailsItem.quarteirao }}</li>
      <li><label>Rua</label>: {{ detailsItem.ruautilizador }}</li>
      <li><label>Cidade</label>: {{ detailsItem.cidadeutilizador }}</li>
      <li><label>Número de Casa</label>: {{ detailsItem.ncasa }}</li>
      <li><label>Função</label>: {{ detailsItem.funcao }}</li>
      <li><label>Departamento</label>: {{ detailsItem.departamento }}</li>
      <li><label>Outro Departamento</label>: {{ detailsItem.outro }}</li>
    </div>
    <div class="row">
      <div
          class="pr-4 col-2"
          role="toolbar"
          aria-label="Toolbar with button groups"
      >
        <div class="btn-group mr-2" role="group" aria-label="First group">
          <button
              type="button"
              class="btn btn-success"
              @click="switchButton(detailsItem, true)"
          >
            Aprovar
          </button>
          <!-- <button
            type="button"
            class="btn btn-success"
            @click="openDetails(detailsItem)"
          >
            Detalhes
          </button> -->
        </div>
      </div>
      <div
          class="pr-4 col-3"
          role="group"
          aria-label="Toolbar with button groups"
      >
        <div class="btn-group mr-1" role="group" aria-label="First group">
          <button
              type="button"
              class="btn btn-danger"
              @click="switchButton(detailsItem, false)"
              style="width: 100%"
          >
            Não Aprovar
          </button>
          <!-- <button type="button" class="btn btn-danger"  @click="switchButton(item)">Não Aprovar</button> -->
        </div>
      </div>
    </div>
  </div>
  <CoreDialog
      v-if="successDialog"
      :dialog="successDialog"
      @close="successDialog = false"
      :title="'Notificação'"
  >
    <template v-slot:content>
      <div>Atualizado com sucesso!</div>
    </template>
  </CoreDialog>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import * as XLSX from "xlsx";
import { ref } from "vue";
import { initializeApp } from "firebase/app";
import { mapGetters, mapActions } from "vuex";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  onSnapshot,
  query,
  limit,
  where
} from "firebase/firestore";
import axios from "axios";
import { getDatabase } from "firebase/database";
import CoreDialog from "../components/dialogs/CoreDialog.vue";
export default {
  name: "HomeView",
  components: {
    CoreDialog,
  },
  computed: {
    ...mapGetters("authenticated", ["authenticatedGetter", "departmentGetter"]),
  },
  setup() {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
   const firebaseConfig = {
    apiKey: "AIzaSyDfJ1jAl_WcMPtm2-I-cxHcRt2lLGWsHK4",
    authDomain: "formulario-dabc5.firebaseapp.com",
    projectId: "formulario-dabc5",
    storageBucket: "formulario-dabc5.appspot.com",
    messagingSenderId: "789638100462",
    appId: "1:789638100462:web:265b0c88c5f487ff024aff",
    measurementId: "G-LJH7EE9PGS"

  };

    const app = initializeApp(firebaseConfig);
    const db = getFirestore();
    const database = getDatabase();

    return {
      app,
      db,
    };
  },
  data() {
    return {
      datas: [],
      datasToShow: [],
      startItem: 0,
      endItem: 20,
      isActive: false,
      detailsDialog: false,
      successDialog: false,
      detailsItem: null,
    };
  },
  methods: {
    nextPage() {
      this.startItem = this.endItem;
      this.endItem += 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },
    previousPage() {
      this.endItem = this.startItem;
      this.startItem -= 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },
    startPage() {
      this.startItem = 0;
      this.endItem = 20;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },

    endPage() {
      this.startItem = this.datas.length - 20;
      this.endItem = this.datas.length;
      this.datasToShow = this.datas.slice(this.startItem, this.endItem);
    },
    // switchApprove
    switchButton(item, state) {
      const docReference = doc(this.db, "docentes", item.id);

      // let data = item
      // data.verified = item.verified ? false : true
      setDoc(
          docReference,
          { verified: state },
          { merge: true }
      ).then(() => {
        // console.log("Document successfully updated!");
        this.detailsDialog = false;
        this.detailsItem = null;
        this.successDialog = true;
      });

      if (!state) {
        this.postEmailsend(item);
      }
    },

    openDetails(item) {
      this.detailsDialog = true;
      this.detailsItem = item;
    },

    async getData() {
      // console.log(this.data);
      console.log(this.departmentGetter)
      if(!this.departmentGetter) {
        return
      }
    
      console.log(this.departmentGetter.department.id)
      const q = query(collection(this.db, "docentes"), where("department", "==", this.departmentGetter.department.id));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let datas = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data["id"] = doc.id;
          datas.push(data);
        });
        this.datas = datas;
        this.datasToShow = this.datas.slice(this.startItem, this.endItem);
        // console.log(this.datas);
      });
      //   await addDoc(collection(this.db, "docentes"), this.data);
      // Enviar os dados para o firebase
    },
    postEmailsend(item) {
      axios.post("https://api.siga.uem.mz/mail/form", {
        username: item.name,
        email: item.email1,
        subject: "FORMULÁRIO DE REGISTO DE DOCENTES NO SIGA",
        text: "O seu formulário não está devidamente preenchido, pedimos que volte a verificar os seus dados  ",
      });
    },

    getAcademicsUnities() {
      // const url = "https://api.siga.uem.mz/organicunit/colleges/"
      const url = "http://127.0.0.1:8000/organicunit/colleges/"
      axios
          .get(url, {
            headers: {
              Authorization:"Token 09368f61b2402bd7976bb92a02759167e45c4a5b",
            },
          })
          .then((response) => {
            // console.log(response.data);
            this.academicOrganics = response.data;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    exportTable() {
      let jsonData = this.datasToShow;

      // return console.log(jsonData)

      const wb = XLSX.utils.book_new();

      const rows = jsonData.map(row => ({
        'Nome': row.name,
        'Email Pessoal': row.email,
        'Email Institucional': row.email1,
        'Número do documento': row.n_doc,
        'Tipo de documento': row.documento,
        'Nuit': row.nuit,
        'Telefone': row.telefone,
        'Bairro do Utilizador': row.BairroUtilizador,
        'Quarteirao': row.quarteirao,
        'Rua': row.Ruautilizador,
        'Cidade': row.Cidadeutilizador,
        'Numero de Casa': row.ncasa,
        'Função': row.funcao,
        'Departamento': row.departamento.name,
        'Outro Departamento': row.outro,
      }));

      console.log(rows)
      const worksheet = XLSX.utils.json_to_sheet(rows);
      console.log(worksheet)

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Docentes");
      XLSX.writeFile(workbook, "ListaDocentes.xlsx");
    },

    detalheFunc(element) {
      // console.log(element)
      // Abrir um modal com os detalhes do elemento
      this.teacher = element;
      this.opened = true;
      this.data = true;
    },
  },
  mounted() {
    // this.getAcademicsUnities()
    
    if (!this.authenticatedGetter) {
      this.$router.push({ name: "login" });
    } else {
      this.getData();
    }
  },
};

  function writeUserData(name, email) {
    const db = getDatabase();
    set(ref(db, "users/" + userId), {
      username: name,
      email: email,
      profile_picture: imageUrl,
    });

  // function switchButton() {
  //   // Get the checkbox
  //   var checkBox = document.getElementById("myCheck");
  //   // Get the output text
  //   var text = document.getElementById("text");

  //   // If the checkbox is checked, display the output text
  //   if (checkBox.checked == true){
  //     text.style.display = "block";
  //   } else {
  //     text.style.display = "none";
  //   }
  // }
}
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(228, 54, 54);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #41ad26;
}

input:focus + .slider {
  box-shadow: 0 0 1px 41ad26;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
