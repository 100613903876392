import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import listadocente from '../views/listadocente.vue'
import Login from '../views/login.vue'
import Secure from '../views/secure.vue'
import AdminView from '../views/Admin.vue'
import Dircourses from '../views/Dircourses.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/lista',
    name: 'listdocente',
    component: listadocente,
    meta: {
      requeresAuth: true
    }
  },

  {
    path: '/Dircourses',
    name: 'Dircourses',
    component: Dircourses
   
  },

  {
    path: '/adminsiga',
    name: 'adminsiga',
    component: AdminView,
    meta: {
      requeresAuth: true
    }
  },

  {
    path: "/login",
    name: "login",
    component: Login
},
{
  path: "/secure",
  name: "secure",
  component: Secure
},

  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})


export default router
